import { BaseLoadingDisplay } from '@/components/base/baseLoadingDisplay';
import BaseTopNav from '@/components/base/baseTopNav';
import FeaturesRenderText from '@/components/features/featuresRenderText';
import { GetTextEnum } from '@/enums/businessEnum';
import { getPrivacyAgreementApi } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { useTranslation } from 'react-i18next';

const isEmptyObject = (obj: object) => Object.keys(obj).length === 0;

export default function VipPage() {
  const { t } = useTranslation();

  const { data: userAgreement = {} as any, loading } = useReq(
    () =>
      getPrivacyAgreementApi({
        type: GetTextEnum.KEY_CUSTOMER_CENTER,
        noMsg: true,
      }),
    {},
  );

  return (
    <div className="flex flex-col sm:min-h-[80vh] min-h-[100vh] bg-no-repeat bg-cover">
      <BaseTopNav title={<div className="flex items-center">{t('LIBERTYCOIN大客戶中心')}</div>} />

      <div className="my-2 min-h-[30vh] text-foreground px-4">
        <BaseLoadingDisplay
          loading={loading}
          list={isEmptyObject(userAgreement) ? [] : [1]}
        >
          <FeaturesRenderText data={userAgreement} />
        </BaseLoadingDisplay>
      </div>
    </div>
  );
}
